@import "@/styles/globals.scss";















svg.triangle-icon {
  fill: white;
}

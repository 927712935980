@import "@/styles/globals.scss";
























button.navigate-back {
  background: none;
  border: 0 none;
  text-transform: uppercase;
  font-size: calc(1rem - 2px);

  &:before {
    content: '';
    display: inline-block;
    width: 1.5em;
    height: 1em;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.44109 8.577C6.15129 9.287 5.05048 10.388 4.33986 9.67802C2.98027 8.31782 1.62129 6.95945 0.261497 5.59965C-0.0376866 5.30047 -0.0376866 4.79802 0.261497 4.49883C1.62272 3.13945 2.98354 1.77965 4.34476 0.419855C5.05435 -0.29239 6.15435 0.809447 5.44435 1.52026C4.26782 2.6972 3.09068 3.87332 1.91354 5.05006C3.08905 6.22435 4.26456 7.40149 5.44109 8.577ZM5.76293 6.50659C6.56905 6.50659 7.2217 5.85292 7.2217 5.0472C7.2217 4.2419 6.56905 3.59047 5.76293 3.59047C4.95721 3.59047 4.30558 4.24169 4.30558 5.0472C4.30578 5.85292 4.95721 6.50659 5.76293 6.50659ZM10.286 6.50659C11.0917 6.50659 11.7448 5.85292 11.7448 5.0472C11.7448 4.2419 11.0917 3.59047 10.286 3.59047C9.47987 3.59047 8.82823 4.24169 8.82823 5.0472C8.82844 5.85292 9.47987 6.50659 10.286 6.50659ZM14.808 6.50659C15.6142 6.50659 16.2666 5.85292 16.2666 5.0472C16.2666 4.2419 15.6142 3.59047 14.808 3.59047C14.0029 3.59047 13.3505 4.24169 13.3505 5.0472C13.3505 5.85292 14.0027 6.50659 14.808 6.50659Z'/%3E%3C/svg%3E%0A");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: left 50%;
    background-color: currentColor;
    vertical-align: -10%;
  }
}

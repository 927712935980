@import "@/styles/globals.scss";


























































































































































































































































.form-input-group .form-input.address {
  width: 100%;
  position: relative;

  label.form-input.address-input.text {
    margin-bottom: 0;
  }
}

@import "@/styles/globals.scss";

































svg.clock-rotate-left-icon {
  stroke: currentColor;
  fill: currentColor;
}

@import "@/styles/globals.scss";
























svg.ticket-icon {
  stroke: white;
}
